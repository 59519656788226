<template>
  <div class="d-flex-column align-center main">
    <div v-if="!success" class="main full-width input-underline">
      <el-card v-if="form">
        <div class="mw-400 main">
          <div class="mw-400 input-underline">
            <el-select placeholder="Select Registered Club" v-model="selectRego" class="full-width">
              <el-option
                v-for="option in regoOptions"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              />
            </el-select>
          </div>
          <el-form label-position="top" class="">
            <SuperInput
              id="applyFor18M"
              v-if="selectRego"
              :field="form.applyFor18M"
              v-model="form.applyFor18M.value"
              :errors.sync="form.applyFor18M.errors"
            />
            <SuperInput
              id="applicationReason"
              v-if="!!form.applyFor18M.value && checkU18"
              :field="form.applicationReason"
              v-model="form.applicationReason.value"
              :errors.sync="form.applicationReason.errors"
            />
            <SuperInput
              id="applicationInformation"
              v-if="!!form.applyFor18M.value && checkU18"
              :field="form.applicationInformation"
              v-model="form.applicationInformation.value"
              :errors.sync="form.applicationInformation.errors"
            />
            <SuperInput
              id="height"
              v-if="!!form.applyFor18M.value && checkU18"
              :field="form.height"
              v-model="form.height.value"
              :errors.sync="form.height.errors"
            />
            <SuperInput
              id="weight"
              v-if="!!form.applyFor18M.value && checkU18"
              :field="form.weight"
              v-model="form.weight.value"
              :errors.sync="form.weight.errors"
            />
          </el-form>
        </div>
      </el-card>
    </div>
    <div v-if="!success" class="d-flex-column main mw-400">
      <div class="align-text-center">
        <el-button
          v-if="form && form.applyFor18M && form.applyFor18M.value"
          id="nextstep"
          type="primary"
          class="full-width"
          @click="submit"
          >Submit</el-button
        >
      </div>
      <div class="align-text-center">
        <el-button class="full-width button-invisible" @click="back">Back</el-button>
      </div>
    </div>
    <div v-if="success" class="d-flex-column main align-center mw-700">
      <el-card class="align-text-center">
        <h3>Dispensation Application Successfully Sent!</h3>
        <p>
          Check back soon to view the progress of your application. Your Club and Association may
          require more information to assess your Dispensation Application. Contact your Club to
          discuss local requirements.
        </p>
      </el-card>
      <el-button
        type="primary"
        class="mt-1 button w-90"
        @click="$router.push({ name: 'dispensations' })"
      >
        Back
      </el-button>
    </div>
  </div>
</template>
<script>
import { reasons18M } from "@/utils/constants/index";
import { positiveIntStrategy } from "@/utils/validators/strategy";
import { errorOrPass, flatten, makeForm } from "@/utils/forms";
import { endpointParams } from "@/utils/constants/api";
import SuperInput from "@/components/form/fields/SuperInput.vue";

export default {
  name: "addDispensations",
  components: { SuperInput },
  mounted() {
    if ("18 Month Registration Window") {
      const { participantId, availableRego, checkU18, dispType } = this.$route.query;
      if (
        !this.$route ||
        !this.$route.query ||
        !checkU18 ||
        !dispType ||
        !participantId ||
        !availableRego ||
        !Array.isArray(availableRego) ||
        !availableRego.length
      ) {
        this.$store.commit("views/PUSH_NOTIFICATION", {
          msg: "Missing participant data",
          type: "warning",
        });
        setTimeout(() => {
          this.$router.push({ name: "dispensations" });
        }, 1000);
        return;
      }
    }
    const { participantId, dispType } = this.$route.query;
    this.participantId = participantId;
    if (dispType === "18 Month Registration Window") {
      const { checkU18 } = this.$route.query;
      const dispensation = dispType;
      const formData = this.dispensationForm[dispensation];
      const form = makeForm(formData);
      this.checkU18 = checkU18;
      this.form = form;
    }
  },
  computed: {
    regoOptions() {
      return this.$route.query &&
        this.$route.query.availableRego &&
        Array.isArray(this.$route.query.availableRego)
        ? this.$route.query.availableRego.map((x) => ({
            label: x.entityName,
            value: x.entityId,
          }))
        : [];
    },
  },
  methods: {
    back() {
      this.$router.go("/profile/dispensations");
    },
    async submit() {
      const flatForm = this.form && flatten(this.form);
      let ignoredFields = [];
      if (this.checkU18 && this.form.applyFor18M && this.form.applyFor18M.value === false) {
        ignoredFields = ["applicationReason", "applicationInformation", "height", "weight"];
      }
      // Validate standard fields
      if (this.form && !errorOrPass(this.form, ignoredFields, this)) return;
      if (
        this.form &&
        this.checkU18 &&
        this.form.applyFor18M &&
        this.form.applyFor18M.value === true
      ) {
        // Push additional forms fields to store against the member profile === height, weight
        this.$store.commit("root/LOADING", true);
        try {
          const response = await this.$http.put(
            endpointParams.profile.updateMinor(this.participantId),
            {
              height: parseInt(flatForm.height),
              weight: parseInt(flatForm.weight),
            }
          );
          if (response.data && response.data.data === this.participantId) {
            const updates = {
              applyFor18M: flatForm.applyFor18M,
              applicationReason: flatForm.applicationReason,
            };
            if (flatForm.applicationInformation)
              updates.applicationInformation = flatForm.applicationInformation;

            const regoRecord = Array.isArray(this.$route.query.availableRego)
              ? this.$route.query.availableRego.find((x) => x.entityId === this.selectRego)
              : {};

            const memberActiveRego = {
              memberId: this.participantId,
              entityType: regoRecord.entityType,
              memberType: regoRecord.memberType,
              entityId: this.selectRego,
              season: regoRecord.season,
            };
            await this.$http.put(endpointParams.profile.dispensation, {
              memberActiveRego,
              updates,
            });
            this.success = true;
          } else {
            window.scrollTo(0, 0);
            this.$store.commit("views/PUSH_NOTIFICATION", {
              msg: msg.error.apiError,
              type: "warning",
            });
          }
          this.$store.commit("root/LOADING", false);
        } catch (error) {
          this.$store.commit("root/LOADING", false);
          window.scrollTo(0, 0);
          this.$store.commit("views/PUSH_NOTIFICATION", {
            msg:
              error.response && error.response.data && error.response.data.message
                ? error.response.data.message
                : msg.error.apiError,
            type: "warning",
          });
        }
      }
    },
  },
  data() {
    return {
      checkU18: null,
      dispensationForm: {
        "18 Month Registration Window": {
          applyFor18M: {
            label: "Apply for 18 Month Provision",
            options: [
              {
                value: true,
                label: "Yes",
              },
              {
                value: false,
                label: "No",
              },
            ],
            component: "SelectSP",
            required: true,
            // displayed: () => true,
          },
          applicationReason: {
            label: "18 Month Application Reason",
            component: "SelectSP",
            options: reasons18M,
            // displayed: () => this.checkU18,
          },
          height: {
            label: "Height (cm)",
            validators: [positiveIntStrategy],
            // displayed: () => this.checkU18,
          },
          weight: {
            label: "Weight (kg)",
            validators: [positiveIntStrategy],
            // displayed: () => this.checkU18,
          },
          applicationInformation: {
            label: "Please provide additional information",
            required: false,
            displayed: () => this.form.applicationReason.value === "Other",
          },
        },
      },
      endpointParams,
      form: undefined,
      participantId: undefined,
      reasons18M: reasons18M,
      selectRego: "",
      success: false,
    };
  },
};
</script>
